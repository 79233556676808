import React from 'react';
import { Avatar, BottomNavigation, BottomNavigationAction, Button, Chip, Divider, List, ListItem, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import { useValue } from '../data';
import { stampToMoment } from '../../utils.mjs';
import { useDrawer } from '../hooks';
import { TimeLine } from './TimeLine';
import { FuvarAction } from './FuvarAction';
import { Chat, LocalAtm, Map as MapIcon, ArrowDownward, ArrowUpward, Directions, DirectionsBus, Hotel, Info, Queue, Assignment, Cached, Check, Visibility, LocalGasStation, Forum } from '@material-ui/icons';
import { Link } from '../rf/Link';
import config from '../config.json'
import { Chatroom } from './Chatroom';

function GpsCoords({ txt }) {
  if (!txt) return null;
  try {
    let crd = txt.replace(/[,;]/g, '.').replace(/\s*/g, '').split('.');
    return <Link style={{ display: 'inline' }} addr={crd[0] + '.' + crd[1] + ',' + crd[2] + '.' + crd[3]}>{txt}</Link>
  } catch (e) {
    console.log(e.stack);
    return txt;
  }
}
// eslint-disable-next-line
const gps_rx = /([-+]?)([\d]{1,2})((([\.,])(\d+)(\s*)([,;])))(\s*)(([-+]?)([\d]{1,3})(([\.,])(\d+))?)/g
function FindCoords({ txt }) {
  if (!txt) return null;
  const m = txt.match(gps_rx);
  if (m) {
    const ret = [];
    for (let i = 0; i < m.length && txt; i++) {
      let t = txt.split(m[0]);
      ret.push(<span key={i}>{t[0]} <GpsCoords txt={m[i]} /></span>);
      txt = t[1];
    }
    if (txt) {
      ret.push(<span key="vege">{txt}</span>);
    }
    return ret;
  }
  return txt;
}

const useStyles = makeStyles(theme => ({
  section: {
    width: '100%',
    padding: 4,
  },
  icon: {
    float: 'left',
  }

}));

function FuvarDesc({ f, tetel, orszaginfo }) {
  const classes = useStyles();
  if (!f) return null;
  console.log("Fuvardesc", f);
  const { msg, ttxt1, ttxt2, ttxt3, ttxt4, lpihi1, lpihi2, lpihi3, pottxt, rakszam, rakomany, akasztas, potkocsi, bye, nemtankol, tapa } = f;
  let li = [
    { Icon: Chat, txt: bye ? msg + ' \n' + bye : msg },
    /* { Icon: AirlineSeatReclineNormal, txt: `${sofor}${sofor2 ? ' + ' + sofor2 : ''}`}*/
  ];
  if (potkocsi) li.push({ Icon: akasztas ? Cached : Check, txt: 'Pótkocsi: ' + potkocsi });
  if (pottxt) li.push({ Icon: DirectionsBus, txt: pottxt });
  if (tetel[0].tcap) {
    tetel.forEach(t => {
      const { rakszam, suly, cargo, cargomemo, cargolabels, mennyiseg, me, varos, epulet } = t;
      let aru = [];
      if (rakszam) aru.push(`Rakodási szám: ${rakszam}`);
      let m = [];
      if (mennyiseg && me) m.push(mennyiseg + ' ' + me);
      if (suly) m.push(suly + ' kg');
      if (cargo) m.push(cargo);
      if (m.length) aru.push(m.join(' | '));
      if (cargomemo) aru.push(cargomemo);
      if (cargolabels) aru.push(cargolabels);
      if (aru.length) {
        let v = [];
        if (varos) v.push(varos);
        if (epulet) v.push(epulet);
        aru.unshift(v.join(', '));
        li.push({ Icon: Assignment, txt: aru.join("\n") });
      }
    })
  } else {
    if (tapa) li.push({ Icon: Visibility, txt: 'TAPA fuvar' });
    if (rakszam) li.push({ Icon: Assignment, txt: 'Rakodási szám: ' + rakszam });
    if (rakomany) li.push({ Icon: Assignment, txt: rakomany });
    if (nemtankol) li.push({ Icon: LocalGasStation, txt: 'Nem szabad tankolni!' });
    if (orszaginfo && orszaginfo.length) li.push({ Icon: LocalAtm, component: orszaginfo.map(o=> <Chip color="primary" label={o.tipus} avatar={<Avatar>{o.orszag}</Avatar>} key={o.orszag} />)});
  }

  if (ttxt1) li.push({ Icon: ArrowUpward, txt: ttxt1 });
  if (ttxt2) li.push({ Icon: Queue, txt: ttxt2 });
  if (ttxt3) li.push({ Icon: ArrowDownward, txt: ttxt3 });
  if (ttxt4) li.push({ Icon: Info, txt: ttxt4 });
  if (lpihi1) li.push({ Icon: Hotel, txt: lpihi1 });
  if (lpihi2) li.push({ Icon: Hotel, txt: lpihi2 });
  if (lpihi3) li.push({ Icon: Hotel, txt: lpihi3 });
  return <List>
    {
      li.map(({ Icon, txt, component }, i) => <ListItem key={i} divider style={{ whiteSpace: 'pre-wrap' }}>
        <ListItemIcon><Icon className={classes.icon} /></ListItemIcon>
        {txt ? <FindCoords txt={txt} /> : null}
        {component || null}
      </ListItem>)
    }
  </List>
}

export function FuvarDetails(props) {
  const aktivjarat = useValue('aktivjarat');
  const list = useValue('jarat');
  const xpot = useValue('potkocsi');
  const { page, openDrawer } = useDrawer();

  console.log('Fuvar', { props, aktivjarat, list });
  let { jarat } = props;
  if (!jarat) jarat = page && page.jarat && page.jarat.jarat;
  if (!jarat) jarat = aktivjarat;
  const p = (page && page.jarat && page.jarat.id) || 'info';

  if (!jarat && list && list.length) {
    const al = list.filter(f => {
      if (!f || f.lezarva || f.lemondva || !f.sajat || !f.tetel || !f.tetel.length) return false;
      if (!f.sajattetel) f.sajattetel = f.tetel.filter(t => t.sajat);
      if (!f.sajattetel.length) return false;
      return true;
    });

    if (al.length) jarat = al[0].pos;
  }
  console.log('Fuvar', { jarat });
  if (!jarat) return null;

  const f = list.find(j => j.pos === jarat);
  console.log("f", f);
  if (!f) return null;
  let { pos, viszonylat, tetel, fuvar, orszaginfo, sajattetel, routeId } = f;
  if (fuvar && fuvar.mref) viszonylat = fuvar.mref;
  if (!viszonylat) viszonylat = tetel.map(t => t.pdisplay).join(' - ');
  let st = stampToMoment(tetel[0].eterv);
  if (fuvar && sajattetel && sajattetel[0]) {
    fuvar.potkocsi = sajattetel[0].potkocsi;
    fuvar.akasztas = fuvar.potkocsi && xpot && fuvar.potkocsi !== xpot.frsz;
  }

  let readmore = <div style={{
    height: 120, position: 'relative', top: -120, marginBottom: -120,
    background: 'linear-gradient(0deg, rgba(66,66,66,1), rgba(66,66,66,0))'
  }}></div>

  let wrapperStyle = { padding: 0, paddingBottom: (p === 'terkep' || p === 'chatroom') ? 0 : 150, flexGrow: 100, overflowY: 'auto' };

  return <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
    <Typography variant="subtitle1" style={{ position: 'relative', textAlign: 'center', background: config.color.primary, padding: 16 }}>
      {pos} | {st.format('YYYY.MM.DD ddd')}<br />
      {viszonylat}
      <FuvarAction f={f} style={{ position: 'absolute', bottom: -24, right: 20, zIndex: 1000 }} />
    </Typography>
    <BottomNavigation
      style={{ paddingRight: '30%' }}
      value={p}
      onChange={(event, value) => { openDrawer('jarat', { ...page.jarat, id: value }, 'replace')(); }}
      showLabels
    >
      <BottomNavigationAction icon={<Chat />} label="Leírás" value="info" />
      <BottomNavigationAction icon={<Directions />} label="Útvonal" value="utvonal" />
      {config.chatroom && <BottomNavigationAction icon={<Forum />} label="Chat" value="chatroom" />}
    </BottomNavigation>
    <div style={wrapperStyle}>
      {p === 'info' && <FuvarDesc f={fuvar} tetel={tetel} orszaginfo={orszaginfo} />}
      {p === 'utvonal' && <><div style={{ margin: 8 }}>
        <FindCoords txt={fuvar.leiras} />
      </div>
        <Divider />
        <div style={{ margin: 8 }}><TimeLine tetel={tetel} routeId={routeId} />
        </div>
      </>}
      {p === 'chatroom' && <Chatroom pos={jarat} />}
    </div>
    {p !== 'chatroom' && readmore}

  </div>
}
